import lottie from 'lottie-web'

const welcomeAnimationContainer = document.querySelector('#welcome-animation')
if (welcomeAnimationContainer) {
  lottie.loadAnimation({
    container: welcomeAnimationContainer,
    renderer: 'svg',
    loop: true,
    autoplay: true,
    // eslint-disable-next-line no-undef
    path: welcomeAnimationURL
  })
}
